<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="付款单号" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="付款单号搜索"
                    class="namec"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="审批状态" prop="state">
                <el-select
                    v-model="form.state"
                    placeholder="选择审批状态"
                    class="statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in this.$store.state.state"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="汇款状态" prop="pay_state">
                <el-select
                    v-model="form.pay_state"
                    placeholder="选择汇款状态"
                    class="pay_statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['待汇款','已汇款']"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="紧急程度" prop="urgent_state">
                <el-select
                    v-model="form.urgent_state"
                    placeholder="选择紧急程度"
                    class="urgent_statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['一般','较急','特急']"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="付款日期" prop="zhwl_pay_time">
                <el-date-picker
                    v-model="form.zhwl_pay_time"
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    class="zhwl_pay_timec"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="申请企业" prop="enterprise_id">
                <el-select
                    v-model="form.enterprise_id"
                    placeholder="选择企业"
                    class="enterprisec"
                    @change="(enterprise_id) => get_department(enterprise_id)"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="enterprise in AllEnterprise"
                        :key="enterprise.name"
                        :label="enterprise.name"
                        :value="enterprise.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="申请部门" prop="department_id">
                <el-select
                    v-model="form.department_id"
                    placeholder="选择部门"
                    class="departmentc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="department in this.departmentInEnterprise"
                        :key="department.name"
                        :label="department.name"
                        :value="department.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="业务付款编号" prop="zhwl_yw_id">
                <el-input
                    v-model="form.zhwl_yw_id"
                    placeholder="搜索业务付款编号"
                    class="zhwl_yw_idc"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="收款单位" prop="zhwl_pay_entertain_name">
                <el-input
                    v-model="form.zhwl_pay_entertain_name"
                    placeholder="搜索收款单位"
                    class="zhwl_pay_entertain_namec"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="付款金额" prop="zhwl_money">
                <el-input
                    v-model="form.zhwl_money"
                    placeholder="搜索金额"
                    class="zhwl_money"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="申请人" prop="zhwl_operator_name">
                <el-input
                    v-model="form.zhwl_operator_name"
                    placeholder="搜索申请人"
                    class="zhwl_operator_namec"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="当前审批人" prop="current_examine_staff">
                <el-select
                    v-model="form.current_examine_staff"
                    placeholder="选择当前审批人"
                    class="current_examine_staffc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="staff in AllStaff"
                        :key="staff.name"
                        :label="staff.name"
                        :value="staff.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(select_pay_auth)"
                type="primary"
                plain
                round
                @click="$emit('pay_select')"
                class="temp_add"
                size="medium"
                >批量汇款</el-button
            >
            <el-button
                v-if="$_has(select_urgent_auth)"
                type="primary"
                plain
                round
                @click="$emit('urgent_select')"
                class="temp_add"
                size="warning"
                >批量加急</el-button
            >
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >新增业务付款申请</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
import { department_list_request,enterprise_list_request } from '@/network/list.js'

export default {
    name: 'BusinessPaymentSearchBarComponent',
    data() {
        return {
            form: {
                name: '',
                state: '',
                pay_state: '',
                urgent_state: '',
                department_id: '',
                enterprise_id: '',
                current_examine_staff: '',
                zhwl_yw_id: '',
                zhwl_pay_entertain_name: '',
                zhwl_operator_name: '',
                zhwl_pay_time: '',
                zhwl_money: '',
                page: 1,
            },
            AllEnterprise: [],
            departmentInEnterprise: []
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
        get_department(enterprise_id) {
            this.form.department_id = ''
            this.departmentInEnterprise = []
            if (enterprise_id) {
                department_list_request({ enterprise_id })
                    .then((s) => {
                        if (s.status === 0) {
                            this.departmentInEnterprise = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err)
                    })
            }
        },
        get_all_enterprise() {
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllEnterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {
        add_auth: String,
        select_pay_auth: String,
        select_urgent_auth: String,
        AllStaff: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {
        this.get_all_enterprise()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>