<template>
    <div v-loading.fullscreen.lock="loading">
        <business-payment-search-bar-component
            add_auth="新增业务付款申请"
            select_pay_auth="业务付款单批量汇款"
            select_urgent_auth="业务付款单批量加急"
            :AllStaff="AllStaff"
            @pay_select="pay_select"
            @urgent_select="urgent_select"
            @search="get_business_payment_index"
            @addTeam="addTeam"
        ></business-payment-search-bar-component>
        <common-table-component
            details_auth="业务付款申请详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_business_payment"
            :duoxuan="true"
            @selects="selects"
            table_height="549px"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_business_payment_index"
        ></common-page-component>
        <business-payment-edit-component
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :business_payment_details_data="business_payment_details_data"
            :enterprise_department="enterprise_department"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :add_file_auth="add_file_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            :pay_auth="pay_auth"
            @show_edit="show_edit"
            @addTeam="addTeam"
            @search="get_business_payment_index"
            @exitDialog="dialogExit"
            @frefresh="frefresh"
            @details_row="details_business_payment"
        ></business-payment-edit-component>
    </div>
</template>

<script>
import {
    business_payment_index_request,
    business_payment_details_request,
    business_payment_select_pay_request,
    business_payment_select_urgent_request
} from '@/network/finance/BusinessPayment.js'
import { enterprise_department_request} from '@/network/enterprise.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'
import { staff_list_request } from '@/network/list.js'

import BusinessPaymentSearchBarComponent from '@/components/finance/BusinessPayment/BusinessPaymentSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import BusinessPaymentEditComponent from '@/components/finance/BusinessPayment/BusinessPaymentEditComponent'

export default {
    name:'BusinessPaymentView',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            loading: false,
            fdialogFormVisible: false,
            cond: {
                name: '',
                state: '',
                pay_state: '',
                urgent_state: '',
                department_id: '',
                enterprise_id: '',
                current_examine_staff: '',
                zhwl_yw_id: '',
                zhwl_pay_entertain_name: '',
                zhwl_operator_name: '',
                zhwl_pay_time: '',
                zhwl_money: '',
                page: 1,
                limit: 10,
            },
            ftotal: 0,
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'zhwl_pay_time',
                    label: '付款日期',
                    minWidth: '120px',
                    editdata(row) {
                        return row['zhwl_pay_time'].slice(0,10)
                    }
                },
                {
                    prop: 'name',
                    label: '付款单号',
                    minWidth: '120px'
                },
                {
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },
                {
                    prop: 'urgent_state',
                    label: '紧急程度',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '一般'
                            }
                        } else if(d===1) {
                            return {
                                type: 'warning',
                                filter_data: '较急'
                            }
                        } else if(d===2) {
                            return {
                                type: 'danger',
                                filter_data: '特急'
                            }
                        }
                    }
                },
                {
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px',
                },
                {
                    prop: 'zhwl_yw_id',
                    label: '付款编号',
                    minWidth: '150px'
                },
                {
                    prop: 'enterprise_name',
                    label: '申请企业',
                    minWidth: '260px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'zhwl_pay_entertain_name',
                    label: '收款单位',
                    minWidth: '220px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'zhwl_currency',
                    label: '币种',
                    minWidth: '80px'
                },
                {
                    prop: 'zhwl_money',
                    label: '付款金额',
                    minWidth: '120px',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['zhwl_money']))
                    }
                },
                {
                    prop: 'zhwl_operator_name',
                    label: '申请人',
                    minWidth: '80px'
                },
                {
                    prop: 'department_name',
                    label: '申请部门',
                    minWidth: '120px'
                },
                {
                    prop: 'create_staff_name',
                    label: '创建人',
                    minWidth: '100px',
                }
            ],
            business_payment_details_data: {},
            enterprise_department: [],
            edit_auth: false,
            del_auth: false,
            //补充附件
            add_file_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //汇款
            pay_auth: false,
            //撤回审批
            withdraw_auth: false,
            select_id: [],
            AllStaff: []
        }
    },
    computed:{},
    methods:{
        get_business_payment_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.state = param.state ?? this.cond.state
            this.cond.pay_state = param.pay_state ?? this.cond.pay_state
            this.cond.urgent_state = param.urgent_state ?? this.cond.urgent_state
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.department_id = param.department_id ?? this.cond.department_id
            this.cond.current_examine_staff = param.current_examine_staff ?? this.cond.current_examine_staff
            this.cond.zhwl_yw_id = param.zhwl_yw_id ?? this.cond.zhwl_yw_id
            this.cond.zhwl_pay_entertain_name = param.zhwl_pay_entertain_name ?? this.cond.zhwl_pay_entertain_name
            this.cond.zhwl_operator_name = param.zhwl_operator_name ?? this.cond.zhwl_operator_name
            this.cond.zhwl_pay_time = param.zhwl_pay_time ?? this.cond.zhwl_pay_time
            this.cond.zhwl_money = param.zhwl_money ?? this.cond.zhwl_money
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            business_payment_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_business_payment(id) {
            this.fid = id
            this.fdialogFormVisible = true
            business_payment_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.business_payment_details_data = s.result
                        this.business_payment_details_data.department_id = [s.result.enterprise_id,s.result.department_id]
                        //权限判断
                        this.is_auth(s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_enterprise_department() {
            this.loading = true
            enterprise_department_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.enterprise_department = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        frefresh() {
            this.details_business_payment(this.fid)
            this.get_business_payment_index()
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.add_file_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.pay_auth = false
            this.withdraw_auth = false
            
            if(res.state === 0) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交业务付款申请')
                    this.edit_auth = this.$_has('修改业务付款申请')
                    this.del_auth = this.$_has('删除业务付款申请')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回业务付款申请')
                    this.add_file_auth = this.$_has('补充业务付款申请附件')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批业务付款申请')
                }
                this.sel_approval_auth = this.$_has('查看业务付款申请审批记录')
            } else if(res.state === 2) {
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.add_file_auth = this.$_has('补充业务付款申请附件')
                }
                this.sel_approval_auth = this.$_has('查看业务付款申请审批记录')
                this.pay_auth = this.$_has('业务付款单汇款')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交业务付款申请')
                    this.edit_auth = this.$_has('修改业务付款申请')
                    this.del_auth = this.$_has('删除业务付款申请')
                }
                this.sel_approval_auth = this.$_has('查看业务付款申请审批记录')
            }
        },
        selects(rows) {
            this.select_id = []
            rows.forEach(v => {
                this.select_id.push(v.id)
            })
        },
        pay_select() {
            if(this.select_id.length === 0) {
                this.$message.error('请先选择需要汇款的业务付款单')
                return
            }

            this.loading = true
            business_payment_select_pay_request(this.select_id)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.$message.success('批量汇款成功')
                        this.get_business_payment_index()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        urgent_select() {
            if(this.select_id.length === 0) {
                this.$message.error('请先选择需要加急的业务付款单')
                return
            }

            this.loading = true
            business_payment_select_urgent_request(this.select_id)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.$message.success('批量加急成功')
                        this.get_business_payment_index()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_enterprise_department()
        this.get_business_payment_index()
        this.get_all_staff()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        BusinessPaymentSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        BusinessPaymentEditComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>